export default {
  en: {
    translations: {
      common: {
        loading: 'Loading',
        decoding: 'Decoding',
        retry: 'Retry',
        add: 'Add',
        filter: 'Filter',
        actions: 'Actions',
        create: 'Create',
        detail: 'Detail',
        upload: 'Upload',
        reset: 'Reset',
        restart: 'Restart',
        samples: 'Samples',
        next: 'Next',
        selectFile: 'Select a file',
        selectDirectory: 'Select a directory',
        save: 'Save',
        delete: 'Delete',
        deleteInView: 'Delete in View',
        sureToDelete: 'Are you sure to delete the object?',
        sureToDeleteInView: 'Are you sure to delete de visiblle objects?"',
        yes: 'Yes',
        no: 'No',
        loadMore: 'Load More',
        successMessage: 'Operation performed successfully',
        info: 'Info',
        period: 'Period',
        pa: 'Pa',
        db: 'dB',
        hz: 'Hz',
        percent: '%',
        cumulativePercent: '% cumulative',
        ms2: 'm/s²',
        cms2: 'cm/s²',
        mms2: 'mm/s²',
        ins2: 'in/s²',
        fts2: 'ft/s²',
        g: 'g',
        dbStep: 'dB step',
        preview: 'Preview',
        value: 'Value',
        channel: 'Channel',
        time: 'Time',
        sample: 'Sample',
        parameter: 'Parameter',
        apply: 'Apply',
        unitOfMeasure: 'Unit of measure',
        parameters: {
          label: 'Parameter',
          parameterClass: 'Class',
          pond: 'Ponderation',
          measureId: 'Id',
          type: 'Type',
          integration: 'Integration',
          unitOfMeasure: 'Unit of measure',
          measureType: ' type',
          measureStep: 'Measure step',
          preview: 'Preview',
          period: 'Period',
          bands: 'Bands',
        },
      },
      validations: {
        required: 'Required',
      },
      headerMenu: {
        workspaces: 'Workspaces',
        quotas: 'Quote',
      },
      rightMenu: {
        quota: 'Used quota {{ quota }}%',
        editProfile: 'Edit profile',
        logout: 'Exit',
      },
      extractGraphQLErrors: {
        networkError: 'Server unreachable, check connection',
        unexpectedError: 'Unexpected error',
      },
      enums: {
        workspaceRoles: {
          WORKSPACE_MANAGER: 'Manager',
          WORKSPACE_VIEWER: 'Viewer',
        },
      },
      quotas: {
        list: {
          title: 'Quotas',
          subtitle: 'Quota\'s list',
          table: {
            user: 'User',
            quota: 'Quota',
            unlimited: 'Unlimited',
          },
        },
        components: {
          filter: {
            search: 'Search',
          },
          form: {
            quota: 'Quota',
            quotaInfo: 'Enter the number of third octave months to 1/8 of a second',
            unlimited: 'Unlimited',
          },
          updateModal: {
            title: 'Quota upgrade',
          },
        },
      },
      workspaces: {
        list: {
          title: 'Workspaces',
          subtitle: 'Workspaces list',
          table: {
            name: 'Name',
            owner: 'Owner',
          },
        },
        detail: {
          tabs: {
            soundSessions: 'Sound Sessions',
            vibrationSessions: 'Vibration Sessions',
            file: 'Files',
            users: 'Users',
            properties: 'Properties',
          },
        },
        soundSessionsTab: {
          title: 'Measure sessions',
          subtitle: 'List of available sessions',
          table: {
            name: 'Name',
            createdAt: 'Creation Date',
            recordDate: 'Registration Date',
            source: 'Source',
            reference: 'Reference',
            duration: 'Duration',
            weight: 'Weight',
            timePreview: 'Time preview',
            samplePreview: 'Sample preview',
          },
        },
        vibrationSessionsTab: {
          title: 'Measure sessions',
          subtitle: 'List of available sessions',
          table: {
            name: 'Name',
            createdAt: 'Creation Date',
            recordDate: 'Registration Date',
            source: 'Source',
            reference: 'Reference',
            duration: 'Duration',
            weight: 'Weight',
            timePreview: 'Time preview',
          },
        },
        fileTab: {
          formTitle: 'Form',
          uploadSuccess: 'File uploaded successfully',
          jobTitle: 'Jobs',
          jobSubtitle: 'List of files loaded in this workspace and related decoding status',
          table: {
            date: 'Date',
            filename: 'File name',
            jobStatus: 'Status',
          },
        },
        userTab: {
          title: 'Users',
          userPermissionsTitle: 'Permissions',
          ownerTitle: 'Owner',
          ownerDisclaimer: 'By changing the owner, you will no longer have access to this workspace.',
        },
        propertyTab: {
          title: 'Edit workspace',
        },
        components: {
          filter: {
            search: 'Search',
          },
          form: {
            name: 'Name',
          },
          createModal: {
            title: 'Mew workspace',
          },
          fileForm: {
            namePrefix: 'Session name prefix',
            timezone: 'Time zone',
            file: 'DL5/VIB/DODL/WAV (100 max)',
          },
        },
      },
      soundSessions: {
        setup: {
          preview: 'Preview',
          inputChannel: 'Input Channel',
          inputAmplification: 'Input Amplification',
          samplingPeriod: 'Sampling Period',
          profilePeriod: 'Profile Period',
          spectrePeriod: 'Spectre Period',
          integrationTime: 'Integration Time',
          backErase: 'Back Erase',
          integrationMode: 'Integration Mode',
          exchangeRate: 'Exchange Rate',
          threshold: 'Threshold',
          criterion: 'Criterion',
          overload: 'Overload',
          L1: 'L1',
          L2: 'L2',
          L3: 'L3',
          L4: 'L4',
          acousticFieldCorrection: 'Acoustic Field Correction',
          windscreenCorrection: 'Windscreen Correction',
          environmentCorrection: 'Environment Correction',
          reportPeriod: 'Report Period',
          triggerEvent: 'Trigger Event',
          statisticParameter: 'Statistic Parameter',
          statisticClass: 'Statistic Class',
          fastLeqPond: 'Fast Leq Pond',
          fftBand: 'FFT Band',
          classIEC61672: 'Class IEC61672',
          parameterArrayIndex: 'Parameter Array Index',
          preamplifierModel: 'Preamplifier Model',
          preamplifierSerial: 'Preamplifier Serial',
          microphoneModel: 'Microphone Model',
          microphoneSerial: 'Microphone Serial',
        },
        detail: {
          tabs: {
            setup: 'Measure Setup',
            measures: 'Measures',
            properties: 'Properties',
          },
        },
        setupTab: {
          title: 'Session Setup',
        },
        measuresTab: {
          title: 'Measures',
          table: {
            name: 'Nsme',
            kind: 'Type',
            period: 'Step',
            parameters: 'Parameters',
            weight: 'Weight',
          },
        },
        propertyTab: {
          title: 'Edit session',
        },
        components: {
          filter: {
            search: 'Search',
            from: 'Start registration',
            to: 'End registration',
          },
          form: {
            name: 'Name',
            reference: 'Reference',
            note: 'Notes',
            source: 'Source',
          },
          infoModal: {
            timeSingleMeasureTitle: 'Time history',
            timeOctaveMeasureTitle: 'Time history octave',
            timeThirdOctaveMeasureTitle: 'Time history third-octave',
            timeStatisticsMeasureTitle: 'Time history statistical',
            timeFFTMeasureTitle: 'Time history FFT',

            sampleSingleMeasureTitle: 'Sample history',
            sampleOctaveMeasureTitle: 'Sample history octave',
            sampleThirdOctaveMeasureTitle: 'Sample history third-octave',

            eventSingleMeasureTitle: 'Time history {{eventName}}',
            eventThirdOctaveMeasureTitle: 'Time history third-octave {{eventName}}',
            eventStatisticsMeasureTitle: 'Time history statistical {{eventName}}',
          },
        },
      },
      soundMeasures: {
        kinds: {
          soundTimeSingleMeasure: 'Time History',
          soundTimeOctaveMeasure: 'Octave Time History',
          soundTimeThirdOctaveMeasure: 'Third-Octave Time History',
          soundTimeFFTMeasure: 'FFT Time History',
          soundTimeStatisticsMeasure: 'Statistics Time History',
          soundSampleSingleMeasure: 'Sample Time History',
          soundSampleOctaveMeasure: 'Sample Octave Time History',
          soundSampleThirdOctaveMeasure: 'Sample Third-Octave Time History',
          soundEventSingleMeasure: 'Event Table',
          soundEventThirdOctaveMeasure: 'Event Third-Octave Table',
          soundEventStatisticsMeasure: 'Event Statistics Table',
        },
        config: {
          type: 'Type',
          integration: 'Integration',
          parameterClass: 'Parameter class',
          label: 'Label',
          id: 'Id',
          unitOfMeasure: 'Unit Of Measure',
          pond: 'Ponderation',
          measureId: 'Measure Id',
          period: 'Period',

          bands: 'Bands',

          classes: 'Classes',
          class: 'Class',

          preview: 'Anteprima',

          measureType: 'Measure type ',
          measureStep: 'Measure step',
        },
        components: {
          filterModal: {
            title: 'Filter',
            availableTimes: 'You have selected the period {{- from}}-{{- to}}',
            range: 'Range',
          },
          tableModal: {
            title: 'Data table',
          },
          charts: {
            table: 'Table',
            excel: 'Excel',
            settings: 'Settings',
            showPa: 'Convert to Pa',
            showDB: 'Convert to dB',
            saveImage: 'Save image',
            showGrid: 'Show Grid',
            hideGrid: 'Hide Grid',
            showPoints: 'Show Points',
            hidePoints: 'Hide Points',
            configModal: {
              title: 'Settings',
              minColorRange: 'Minimum',
              maxColorRange: 'Maximum',
              colorSteps: 'Colors step',
            },
          },
          serieAttachmentList: {
            title: 'Attachments',
          },
        },
      },
      soundTimeMeasures: {
        chart: {
          title: '',
          subtitle: 'Available data from {{- from}} to {{- to}}. Are present {{- totalValues}} measurements.',
        },
      },
      soundSampleMeasures: {
        chart: {
          title: '',
          subtitle: 'Available data from {{- from}} to {{- to}}. Are present {{- totalValues}} measurements totali.',
          subsubtitle: 'First sample at {{- date}}',
        },
      },
      vibrationSessions: {
        setup: {
          instrument: {
            model: 'Model',
            serialNumber: 'Serial Number',
            version: 'Version',
            option: 'Option',
          },
          sensor: {
            channelSensitibities: 'Channel sensitibities',
            polarizationVoltages: 'Polarizzation',
            configuration: '# configuratioe',
            type: 'Type',
            sensibility: 'Sensibility',
            manufacturer: 'Manufacturer',
            model: 'Model',
            serialNumber: 'Serial Number',
            fullScale: 'Full scale',
          },
          measure: {
            rightPonds: 'Right sensor weights',
            leftPonds: 'Left sensor weights',
            rightRatios: 'Right sensor coefficients',
            leftRatios: 'Left sensor coefficients',
            mode: 'Mode',
            gain: 'Gain',
            integrationDelay: 'Integration Delay',
            integrationMode: 'Integration Mode',
          },
        },
        components: {
          form: {
            name: 'Nome',
            reference: 'Reference',
            note: 'Notes',
            source: 'Source',
          },
          infoModal: {
            timeSingleMeasureTitle: 'Time History',
            timeOctaveMeasureTitle: 'Octave Time History',
            timeThirdOctaveMeasureTitle: 'Third-Octave Time History',
            timeStatisticsMeasureTitle: 'Statistics Time History',
          },
        },
        detail: {
          tabs: {
            setup: 'Measure setup',
            measures: 'Measures',
            properties: 'Properties',
          },
        },
        setupTab: {
          title: 'Session Setup',
          instrument: 'Instrument',
          rightSensor: 'Right Sensor',
          leftSensor: 'Left Sensor',
          measure: 'Measure',
        },
        measuresTab: {
          title: 'Measures',
          table: {
            name: 'Name',
            kind: 'Kind',
            weight: 'Weight',
          },
        },
        propertyTab: {
          title: 'Edit sessione',
        },
      },
      vibrationMeasures: {
        kinds: {
          vibrationTimeSingleMeasure: 'Time History',
          vibrationOctaveMeasure: 'Octave Time History',
          vibrationTimeThirdOctaveMeasure: 'Third-Octave Time History',
          vibrationTimeFFTMeasure: 'FFT Time History',
          vibrationTimeStatisticsMeasure: 'Statistics Time History',
        },
        config: {
          id: 'Id',
          label: 'Label',
          parameter: 'Parameter',
          unitOfMeasure: 'Unit Of Measure',
          pond: 'Ponderation',
          measureId: 'Measure Id',
          period: 'Period',
          channel: 'Channel',

          bands: 'Bands',

          classes: 'Classes',

          preview: 'Preview',
        },
        components: {
          filterModal: {
            title: 'Filter',
            availableTimes: 'You have selected the period {{- from}}-{{- to}}',
            range: 'Range',
          },
          tableModal: {
            title: 'Data table',
          },
          charts: {
            table: 'Table',
            excel: 'Excel',
            settings: 'Settings',
            showPa: 'Convert to Pa',
            showDB: 'Convert to dB',
            saveImage: 'Save image',
            showGrid: 'Show Grid',
            hideGrid: 'Hide Grid',
            showPoints: 'Show Points',
            hidePoints: 'Hide Points',
            configModal: {
              title: 'Settings',
              minColorRange: 'Minimum',
              maxColorRange: 'Maximum',
              colorSteps: 'Colors step',
            },
          },
        },
      },
      vibrationTimeMeasures: {
        chart: {
          title: '',
          subtitle: 'Available data from {{- from}} to {{- to}}. Are present {{- totalValues}} total measurements.',
        },
      },
    },
  },
};
